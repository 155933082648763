<template>
<body>
    <!-- ::::::  Start Header Section  ::::::  -->
    <Header />
    <!-- :::::: End Header Section ::::::  -->
    <!-- ::::::  Start  Breadcrumb Section  ::::::  -->
    <div class="page-breadcrumb">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <ul class="page-breadcrumb__menu">
                        <li class="page-breadcrumb__nav">
                            <router-link to="/">Home</router-link>
                        </li>
                        <li class="page-breadcrumb__nav active">Cart</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
    <!-- ::::::  End  Breadcrumb Section  ::::::  -->
    <!-- ::::::  Start  Main Container Section  ::::::  -->
    <main id="main-container" class="main-container inner-page-container">
        <div class="container" v-if="cartCount.count>0">
            <div class="row">
                <div class="col-12">
                    <div class="section-content">
                        <h5 class="section-content__title">Your cart items</h5>
                    </div>
                    <!-- Start Cart Table -->
                    <div class="table-content table-responsive cart-table-content m-t-30">
                        <table>
                            <thead class="gray-bg">
                                <tr>
                                    <th>Image</th>
                                    <th>Product Name</th>
                                    <th>Until Price</th>
                                    <th>Qty</th>
                                    <th>Subtotal</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="cart in cartCount.data" :key="cart.id">
                                    <td class="product-thumbnail">
                                        <router-link :to="{ name: 'productDetail', params: { id: cart.pid } }">
                                            <img :src="$root.URL_ROOT+'uploads/Product/'+cart.front_image" class="py-1 img-fluid" alt="">
                                        </router-link>
                                    </td>
                                    <td class="product-name">
                                        <router-link :to="{ name: 'productDetail', params: { id: cart.pid } }" class="p-color"> {{cart.name}} </router-link>
                                    </td>
                                    <td class="product-price-cart"><span class="amount">INR {{cart.amount}} </span></td>
                                    <td class="product-quantities">
                                        <div class="product-quantity product-var__item d-flex align-items-center">
                                            <form class="quantity-scale ">
                                                <div class="value-button  pd-10" :class="cart.qty==1 ? 'not-active' : ''" @click="minusCart(cart.id,cart.qty)">-</div>
                                                <input type="number" id="quantity"  :value="cart.qty" class="text-center w-65 border" />
                                                <div class="value-button  pd-10"  @click="addCart(cart.id,cart.qty)" >+</div>
                                            </form>
                                        </div>
                                    </td>
                                    <td class="product-subtotal">{{cart.totalamount}}</td>
                                    <td class="product-remove">
                                        <a @click="remove(cart.id)"><i class="fa fa-times"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div> <!-- End Cart Table -->
                    <!-- Start Cart Table Button -->
                    <div class="cart-table-button m-t-10">
                        <div class="cart-table-button--left">
                            <a href="#" class=" btn--uppercase font--bold m-t-20 text-green color-green"><i class="fa fa-angle-left" aria-hidden="true"></i><i class="fa fa-angle-left" aria-hidden="true"></i> CONTINUE SHOPPING</a>
                        </div>
                        <!-- <div class="cart-table-button--right">
                            <a href="#" class="btn btn--box btn--large btn--radius btn--green btn--green-hover-black btn--uppercase font--bold m-t-20 m-r-20">UPDATE SHOPPING CART</a>
                            <a href="#" class="btn btn--box btn--large btn--radius btn--black btn--black-hover-green btn--uppercase font--bold m-t-20">Clear Shopping Cart</a>
                        </div> -->
                    </div> <!-- End Cart Table Button -->
                </div>
            </div>
            <div class="row">
                <div class="col-lg-6 col-md-6">
                     <div class="sidebar__widget m-t-40" v-if="myCouponMoney>0"> Coupon Applyed <span> - {{myCouponMoney}}% 
                         </span> </div>
                    <div class="sidebar__widget m-t-40" v-else>
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">Use Coupon Code</h5>
                        </div>
                        <span>Enter your coupon code if you have one.</span>
                        <form action="#" method="post" class="form-box">
                            <div class="form-box__single-group">
                                <label for="form-coupon">*Enter Coupon Code</label>
                                <input type="text" id="form-coupon" v-model="couponCode">
                            </div>
                            <div class="from-box__buttons m-t-25">
                                <button @click="applyCoupon" class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold" type="button">Apply Coupon</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="sidebar__widget m-t-40">
                        <div class="sidebar__box">
                            <h5 class="sidebar__title">Cart Total</h5>
                        </div>
                        <h6 class="total-cost">Total products Price<span>INR {{cartCount.total_amount}}</span></h6>
                        <div class="total-shipping">
                            <span>Total shipping</span>
                            <ul class="shipping-cost m-t-10">
                                <li>
                                    <label for="ship-standard">
                                        <input type="radio" class="shipping-select " name="shipping-cost" value="Standard" id="ship-standard" checked><span>Standard</span>
                                    </label>
                                    <span class="ship-price" v-if="cartCount.total_amount>=min">INR {{shipping}}
                                    </span>
                                </li>
                                  <li  v-if="myCouponMoney>0">
                                    <label for="ship-standard">
                                        <input type="radio" class="shipping-select " name="shipping-costs" value="Standard" id="ship-standards" checked><span>Coupon Applyed</span>
                                    </label>
                                    <span class="ship-price color-green"  v-if="myCouponMoney>0"> {{myCouponMoney}}%
                                    </span>
                                </li>
                            </ul>
                        </div>
                        <h4 class="grand-total m-tb-25">Grand Total
                            <span v-if="cartCount.total_amount>=min">INR {{parseFloat(cartCount.total_amount+shipping) + parseFloat((myCouponMoney / 100) * cartCount.total_amount) }} </span>
                            <span v-else>INR {{parseFloat(cartCount.total_amount)  + parseFloat((myCouponMoney / 100) * cartCount.total_amount)}} </span>
                        </h4>
                        <!-- <button class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold" type="button">PROCEED TO CHECKOUT</button> -->
                        <router-link v-if="!uid" :to="{ name: 'login'}" class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold">LOGIN FOR CHECKOUT</router-link>
                        <button v-if="!uid" @click="guestCheckOut" class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold">GUEST CHECKOUT</button>
                        
                        <router-link v-if="uid" :to="{ name: 'checkout'}" class="btn btn--box btn--small btn--radius btn--green btn--green-hover-black btn--uppercase font--semi-bold">PROCEED TO CHECKOUT</router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="container" v-else>
            <div class="row">
                <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12 offset-lg-3 offset-md-3 offset-sm-0 offset-xs-0 card pd-20 text-center color-green">
                    <h2>No Cart Item Added</h2>
                </div>
            </div>
        </div>
    </main> <!-- ::::::  End  Main Container Section  ::::::  -->
    <!-- ::::::  Start  Footer ::::::  -->
    <Footer />
    <!-- ::::::  End  Footer ::::::  -->

    <!-- material-scrolltop button -->
    <button class="material-scrolltop" type="button"></button>
</body>
</template>

<script>
require('../assets/css/main.css')
import axios from 'axios'
import Header from "@/components/navbar";
import Footer from "@/components/footer";
export default {
    props: ["catedatas"],
    components: {
        Header,
        Footer
    },
    data() {
        return {
            data: "",
            cartCount: [],
            couponCode: [],
            shipping: 0,
            min: 0,
            card: "",
            uid: localStorage.getItem("user"),
            ip_address: JSON.parse(localStorage.getItem("ip_address")),
            myCouponMoney: localStorage.getItem('coupon') ? localStorage.getItem('coupon') : 0,
            fields: {
                type: "update_profile",
                uid: "",
                fullname: "",
                mobile: "",
                address: "",
                Token: this.$root.auth,
            },
        };
    },
    watch: {
        cartCount: function (cartCount) {
            this.cartCount = cartCount;
            // this.getCart();
        },
    },
    mounted() {
        this.uid = localStorage.getItem("user");
        this.goToTop();
        this.getCart();
        this.shippingCharge()
    },
    methods: {
        applyCoupon() {
            var self = this
            axios({
                method: 'POST',
                url: this.$root.URL_ROOT + 'api.php',
                data: {
                    type: 'couponapply',
                    coupon: self.couponCode,
                    uid: self.uid
                }
            }).then(function (response) {
                var data = response.data
                if (data.status == "success") {
                    self.myCouponMoney = data.data
                    localStorage.setItem('coupon', data.data)
                    localStorage.setItem('coupon_name', self.couponCode)
                    self.$toasted.global.success({
                        message: data.msg
                    });
                } else {
                    self.$toasted.global.error({
                        message: data.msg
                    });
                }
            })
        },
        addCart(id, qty) {
            var ev = this
            axios.post(this.$root.URL_ROOT + "api.php", {
                    type: 'addQty',
                    cart_id: id,
                    qty: qty
                })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status == "success") {
                        ev.$toasted.global.success({
                            message: response.data.msg
                        });
                        ev.getCart()
                    } else {
                        ev.$toasted.global.error({
                            message: response.data.msg
                        });
                    }
                });
        },
        minusCart(id, qty) {
            var ev = this
            axios.post(this.$root.URL_ROOT + "api.php", {
                    type: 'minQty',
                    cart_id: id,
                    qty: qty
                })
                .then(function (response) {
                    console.log(response);
                    if (response.data.status == "success") {
                        ev.$toasted.global.success({
                            message: response.data.msg
                        });
                        ev.getCart()
                    } else {
                        ev.$toasted.global.error({
                            message: response.data.msg
                        });
                    }
                });
        },
        remove(id) {
            var ev = this;
            var param = {
                id: id,
                table: 'mss_cart',
                type: 'commonDelete'
            }
            this.$swal({
                title: "Delete this ?",
                text: "Are you sure? You won't be able to revert this!",
                icon: 'info',
                showCancelButton: true,
                confirmButtonColor: "#f00",
                confirmButtonText: "Yes, Delete it!",
                reverseButtons: true
            }).then((result) => {
                if (result.isConfirmed) {
                    axios({
                        method: 'POST',
                        url: this.$root.URL_ROOT + 'api.php',
                        data: param
                    }).then(function (response) {
                        var data = response.data;
                        ev.getCart()
                        console.log(data)
                        // this.deleted = data
                        ev.$toasted.global.success({
                            message: 'Deleted successfully'
                        })
                    })
                }
            })
        },
        goToTop() {
            document.body.scrollTop = 0;
            document.documentElement.scrollTop = 0;
        },
        update_cart_pre(cid, pid, pname, qty, price, mode) {
            var t = this.update_cart(cid, pid, pname, qty, price, mode);
            console.log(t)
            setTimeout(() => {
                if (t != null) {
                    this.getCart();
                }
            }, 1000);
        },
        guestCheckOut(){
            let ev=this;
            let vi=this;

            axios.post(this.$root.URL_ROOT + "api.php", {
                    type: 'saveGuest',
                    ip: this.ip_address,
                })
                .then(function (response) {
                    var data=response.data;
                    console.log(data)
                    if (data.status === 'success') {
                       localStorage.setItem('user', data.session.uid)
                        localStorage.setItem('token', data.session.uid)
                        // vi.$toasted.global.success({
                        //     message: 'Login Success Please wait...'
                        // })
                         setTimeout(() => {
                            vi.$router.push({ name: 'guestcheckout' });
                            vi.$router.push('/guestcheckout');
                         }, 2000);
                      
                    } else {
                        vi.$toasted.global.error({
                            message: 'Invalid id or password'
                        })
                    }

                })


        }
    },
};
</script>

<style scoped>
.shopping-cart .img-thumbnail {
    width: 50px;
}
</style>
